<template>
  <div class="page">
    <section class="hero is-banner is-primary">
      <div class="hero-body">
        <div class="container">
          <h2 class="has-text-centered is-3 title">
            {{ $t('Add New Partner') }}
          </h2>
        </div>
      </div>
    </section>
    <div class="section">
      <div class="container is-mobi">
        <PartnerForm
          ref="form"
          :is-submitting="isSubmitting"
          :property-list="propertyList"
          @submit="handleSubmit"
        />
        <a-alert v-if="partner" type="success">
          <template #message>
            <span v-html="$t('added', { name: partner.name })" />
            <router-link to="/partners">
              {{ $t('view') }}
            </router-link>
            .
          </template>
        </a-alert>
        <a-alert v-if="error" :message="error" type="error" />
      </div>
    </div>
  </div>
</template>

<i18n>
{
"en": {
"Add New Partner": "Add New Partner",
"added": "partner <strong>{name}</strong> added,",
"view": "View partners page"
},
"ja": {
"Add New Partner": "取引先を追加",
"added": "取引先 <strong>{name}</strong> が追加されました。",
"view": "取引先を閲覧"
}
}
</i18n>

<script>
import PartnerForm from '@/views/partners/components/PartnerForm';

export default {
  name: 'Add',
  components: { PartnerForm },
  data() {
    return {
      partner: undefined,
      isSubmitting: false,
      error: '',
      propertyList: [],
    };
  },
  created() {
    this.getPropertyList();
  },
  methods: {
    getPropertyList() {
      this.$store.dispatch('properties/list', { infinite: true }).then((res) => {
        this.propertyList = res.list;
      });
    },
    handleSubmit(data) {
      this.isSubmitting = true;
      this.partner = undefined;
      this.error = '';
      this.$store
        .dispatch('partners/add', data)
        .then(() => {
          this.isSubmitting = false;
          this.partner = data;
          this.$refs.form.resetFields();
        })
        .catch((err) => {
          if (err.response && err.response.data && err.response.data.errors) {
            this.error = err.response.data.errors.message;
          }
          this.isSubmitting = false;
        });
    },
  },
};
</script>
