<template>
  <div class="page">
    <section class="hero is-banner is-primary">
      <div class="hero-body">
        <div class="container">
          <div class="columns is-mobile">
            <div class="column">
              <h2 class="is-3 title">
                {{ $t('Partners') }}
              </h2>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="section">
      <div class="container">
        <div class="columns is-mobi">
          <div class="column">
            <SearchForm />
          </div>
          <div v-permission="['add-partner']" class="column is-narrow">
            <router-link class="ant-btn ant-btn-lg ant-btn-primary" to="/partners/add">
              {{ $t('Add New Partner') }}
            </router-link>
          </div>
        </div>
        <a-table
          :columns="columns"
          :data-source="partners"
          :loading="isFetching"
          :custom-row="rowClicked"
          :pagination="pagination"
          row-key="id"
          @change="handleTableChange"
        >
          <template #id="{ text }">
            {{ text }}
          </template>
          <template #name="{ record }">
            {{ record.name }}
          </template>
          <template #state="{ record }">
            {{ $t(record.state) }}
          </template>
          <template #contactNumber="{ record }">
            {{ (record.personsInCharge.length && record.personsInCharge[0].phone) || '' }}
          </template>
          <template #contactPerson="{ record }">
            {{ (record.personsInCharge.length && record.personsInCharge[0].name) || '' }}
          </template>
          <template #status="{ record }">
            <div @click.stop>
              <a-switch
                v-model:checked="record.isActive"
                :disabled="!canEditPartner"
                @change="(status) => { updatePartnerStatus(status, record); }"
              />
            </div>
          </template>
        </a-table>
      </div>
    </div>
  </div>
</template>

<i18n src="@/locales/components/prefectures.json"></i18n>
<!-- eslint-disable -->
<i18n>
{
"en": {
"Partners": "Partners",
"Add NEW Partner": "Add NEW Partner",
"Partner": "Partner",
"Prefecture": "Prefecture",
"Contact number": "Contact number",
"Contact person": "Contact person",
"Status": "Status",
"title": "Delete partner?",
"desc": "Are you use you want to delete <strong>{name}</strong> ?<br> Once deleted, you cannot recover this partner and its data.",
"deleted-title": "Partner is deleted",
"deleted-desc": "Partner <strong>{name}</strong> has been removed."
},
"ja": {
"Partners": "取引先",
"Add NEW Partner": "取引先を追加",
"Partner": "取引先",
"Prefecture": "都道府県",
"Contact number": "連絡先",
"Contact person": "連絡先氏名",
"Status": "ステータス",
"title": "取引先を削除?",
"desc": "本当にこの <strong>{name}</strong> を削除しますか？<br>一度削除するとこの取引先のデータは復旧できません",
"deleted-title": "取引先を削除",
"deleted-desc": "取引先<strong>{name}</strong>が削除されました"
}
}
</i18n>
<!-- eslint-enable -->

<script>
import pagination from '@/components/mixins/pagination';
import checkPermission from '@/utils/permission';
import SearchForm from '@/views/partners/components/SearchForm';

export default {
  name: 'Partners',
  components: {
    SearchForm,
  },
  mixins: [pagination],
  data() {
    return {
      partners: undefined,
      isFetching: false,
      columns: [
        {
          title: '#',
          dataIndex: '_id',
          key: '_id',
          slots: { customRender: 'id' },
        },
        {
          title: this.$t('Partner'),
          dataIndex: 'name',
          slots: { customRender: 'name' },
        },
        {
          title: this.$t('Prefecture'),
          dataIndex: 'state',
          key: 'state',
          slots: { customRender: 'state' },
        },
        {
          title: this.$t('Contact number'),
          dataIndex: 'contactNumber',
          key: 'contactNumber',
          slots: { customRender: 'contactNumber' },
        },
        {
          title: this.$t('Contact person'),
          dataIndex: 'contactPerson',
          key: 'contactPerson',
          slots: { customRender: 'contactPerson' },
        },
        {
          title: this.$t('Status'),
          key: 'isActive',
          dataIndex: 'isActive',
          slots: { customRender: 'status' },
        },
      ],
    };
  },
  computed: {
    canEditPartner() {
      return checkPermission(['modify-partner']);
    },
  },
  watch: {
    $route(route) {
      if (route.name === 'partners') this.fetchData();
    },
  },
  created() {
    this.fetchData();
  },
  methods: {
    async handleTableChange(pagination) {
      await this.preparePagination(pagination);
      this.fetchData();
    },
    fetchData() {
      this.$store.commit('SHOW_FULLSCREEN_LOADER');
      this.$store
        .dispatch('partners/list', this.$route.query)
        .then((resp) => {
          const { list, pagination } = resp;
          this.partners = list.map((p, index) => {
            p._id = index + 1;
            return p;
          });
          this.setPagination(pagination);
        })
        .finally(() => {
          this.$store.commit('HIDE_FULLSCREEN_LOADER');
        });
    },
    updatePartnerStatus(status, record) {
      this.$store.commit('SHOW_FULLSCREEN_LOADER');
      // eslint-disable-next-line no-unused-vars
      const { _id, ...rest } = record;
      this.$store.dispatch('partners/update', { id: record.id, isActive: status }).finally(() => {
        this.fetchData();
      });
    },
    rowClicked(record) {
      return {
        onClick: () => {
          this.$store.dispatch('routers/addFirstLevelQuery', {
            query: this.$route.query,
            name: 'partners',
          });
          this.$router.push({ name: 'partner-overview', params: { id: record.id } });
        },
      };
    },
    contactNumber(record) {
      this.$router.push({ name: 'partner-room', params: { id: record.id } });
    },
  },
};
</script>
