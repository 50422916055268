<template>
  <div class="columns form is-mini-gap is-multiline">
    <div class="column is-narrow">
      <a-input
        v-model:value="partnerName"
        style="min-width: 200px"
        :size="size"
        :placeholder="$t('Search partner name')"
        @change="debounceQuery"
      />
    </div>
    <div class="column">
      <a-button
        v-show="partnerName"
        :size="size"
        style="min-width: 110px"
        type="default"
        @click="handleClear"
      >
        {{ $t('Clear') }}
      </a-button>
    </div>
  </div>
</template>

<i18n>
{
"en": {
  "Search partner name": "Search partner name",
  "Clear": "Clear"
},
"ja": {
  "Search partner name": "パートナー名を検索",
  "Clear": "クリア"
}
}
</i18n>

<script>
import debounce from 'lodash/debounce';

export default {
  name: 'SearchForm',
  components: {},
  props: {
    size: {
      type: String,
      default: 'large',
    },
    dateFormat: {
      type: String,
      default: 'MM/DD/YYYY',
    },
  },
  data() {
    return {
      query: undefined,
      range: [],
      partnerName: undefined,
    };
  },
  computed: {
    rangeString() {
      return this.range.length
        ? `${this.$filters.date(this.range[0], { format: this.dateFormat })} ~ ${this.$filters.date(
          this.range[1],
          { format: this.dateFormat },
        )}`
        : '';
    },
    formattedRange() {
      const dateFormat = 'YYYY-MM-DD';
      return this.range.length
        ? `${this.$filters.date(this.range[0], { format: dateFormat })},${this.$filters.date(
          this.range[1],
          { format: dateFormat },
        )}`
        : undefined;
    },
  },
  mounted() {
    this.partnerName = this.$route.query.name || undefined;
    // this.query = this.$route.query.query || undefined
    // this.range = this.$route.query.range ? this.$route.query.range.split(',') : []
  },
  methods: {
    debounceQuery: debounce(function () {
      this.$router.push({ query: { name: this.partnerName } });
    }, 600),
    handleClear() {
      this.partnerName = undefined;
      this.query = undefined;
      this.range = [];
      this.debounceQuery();
    },
  },
};
</script>

<style scoped></style>
